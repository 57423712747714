import ComboBoxInputComponent from '../components/ComboBoxInput/viewer/ComboBoxInput';
import ComboBoxInputController from '../components/ComboBoxInput/viewer/ComboBoxInput.controller';


const ComboBoxInput = {
  component: ComboBoxInputComponent,
  controller: ComboBoxInputController
};


export const components = {
  ['ComboBoxInput']: ComboBoxInput
};

